import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import api from 'src/utils/api/Api';
import CustomPopUp from '../../../components/CustomPopUp';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
  Snackbar,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import FormDialog from '../../../components/FormDialog';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  btnDanger: {
    color: theme.palette.danger.color,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: '#9a0036'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Results = ({ className, customers, reload, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [open, setOpen] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const removeCustomer = async customer => {
    let response = await api.removeCategory(customer.id);
    if (response) {
      let newCustomers = customers.filter(c => c.id !== customer.id);
      reload(newCustomers);
      setShowDeleteDialog(false);
    }
  };

  const editCategory = async newName => {
    setLoading(true);
    let category = selectedCategory;
    category.name = newName;
    let response = await api.editCategory(category);
    if (response) {
      let newCustomers = customers.map(c => {
        if (c.id === category.id) c.name = newName;
        return c;
      });
      reload(newCustomers);
      setOpen(false);
      setSucessAlert(true);
    } else {
      setFailAlert(true);
    }
    setLoading(false);
  };

  const setStatus = async customer => {
    setLoading(true);
    customer.active = !customer.active;
    let response = await api.editCategory(customer);
    if (response) {
      let newCustomers = customers.map(c => {
        if (c.id === customer.id) c = customer;
        return c;
      });
      reload(newCustomers);
      setSucessAlert(true);
    }
    setLoading(false);
  };

  return (
    <>
      <CustomPopUp
        visible={showDeleteDialog}
        setShow={setShowDeleteDialog}
        defaultActionTitle="Não"
        message="Deseja mesmo remover esse item?"
        key={selectedCategory.id}
      >
        <Button onClick={() => removeCustomer(selectedCategory)}>Sim</Button>
      </CustomPopUp>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Categoria Atualizada com Sucesso
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao Atualizar Categoria
        </Alert>
      </Snackbar>
      <FormDialog
        show={open}
        setShow={setOpen}
        item={selectedCategory.name}
        saveNewCategory={editCategory}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Marcas</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Ultima Atualização</TableCell>
                  <TableCell>Ativo</TableCell>
                  <TableCell
                    style={{ alignItems: 'center', textAlign: 'center' }}
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers
                  .slice(page * limit, page * limit + limit)
                  .map(customer => {
                    customer['showDeleteDialog'] = false;
                    return (
                      <TableRow
                        hover
                        key={customer.id}
                        selected={
                          selectedCustomerIds.indexOf(customer.id) !== -1
                        }
                      >
                        <TableCell>
                          <Checkbox />
                        </TableCell>
                        <TableCell>{customer.id}</TableCell>
                        <TableCell>
                          <b> {customer.name}</b>
                        </TableCell>
                        <TableCell>
                          <List dense={true}>
                            {customer.brands.map(b => (
                              <ListItem>
                                <ListItemText primary={b.name} />
                              </ListItem>
                            ))}
                          </List>
                        </TableCell>
                        <TableCell>
                          {new Date(customer.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {new Date(customer.updatedAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={customer.active}
                                onChange={async () => {
                                  await setStatus(customer);
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Grid item xs={12}>
                            <Grid container justify="center" spacing={2}>
                              <Grid key={1} item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  startIcon={<EditOutlinedIcon />}
                                  onClick={() => {
                                    setSelectedCategory(customer);
                                    setOpen(true);
                                  }}
                                >
                                  Editar
                                </Button>
                              </Grid>
                              <Grid key={1} item>
                                <Button
                                  variant="contained"
                                  color="danger"
                                  className={classes.btnDanger}
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectedCategory(customer);
                                    setShowDeleteDialog(!showDeleteDialog);
                                  }}
                                >
                                  Deletar
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
