import React, { useState, useEffect } from 'react';
import api from 'src/utils/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { normalizeAndIgnoreDacritics } from 'src/utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BrandView = () => {
  const classes = useStyles();
  const [brands, setBrands] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    const fetchBrands = async () => {
      let brands = await api.getBrands();
      setFilteredList(brands);
      setBrands(brands);
    };
    fetchBrands();
  }, []);

  const search = event => {
    const { value } = event.target;
    if (!value) {
      setFilteredList(brands);
      return;
    }
    const filteredResults = brands.filter(brand =>
      normalizeAndIgnoreDacritics(brand.name).includes(
        normalizeAndIgnoreDacritics(value)
      )
    );
    setFilteredList(filteredResults);
  };

  return (
    <Page className={classes.root} title="Marcas">
      <Container maxWidth={false}>
        <Toolbar
          reload={setFilteredList}
          brands={filteredList}
          filter={search}
        />
        <Box mt={3}>
          <Results reload={setFilteredList} brands={filteredList} />
        </Box>
      </Container>
    </Page>
  );
};

export default BrandView;
