import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';

const FormBrand = ({ className, show, setShow, item, saveBrand, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [newBrand, setNewBrand] = useState('');

  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {!item ? 'Nova Marca' : 'Editar Marca'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!item
            ? 'Para cadastrar uma nova marca, insira o nome da mesma abaixo e clique em salvar.'
            : 'Altere a descrição da marca e clique em Editar'}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Marca"
          defaultValue={item}
          onChange={e => setNewBrand(e.target.value.toUpperCase())}
          type="text"
          fullWidth
          autoCapitalize
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            saveBrand(newBrand);
          }}
          color="primary"
        >
          {!item ? 'Salvar' : 'Editar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormBrand;
