import React, { useState, useEffect } from 'react';
import api from 'src/utils/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { normalizeAndIgnoreDacritics } from 'src/utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductListView = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const getProducts = async () => {
    let result = await api.getProductsByCategoryId(8);
    setProducts(result);
    setFilteredList(result);
  };

  const search = event => {
    const { value } = event.target;
    if (!value) {
      setFilteredList(products);
      return;
    }
    const filteredResults = products.filter(product =>
      normalizeAndIgnoreDacritics(
        product.brand.concat(' ' + product.name)
      ).includes(normalizeAndIgnoreDacritics(value))
    );
    setFilteredList(filteredResults);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Page className={classes.root} title="Produtos">
      <Container maxWidth={false}>
        <Toolbar
          reload={setFilteredList}
          filter={search}
          products={filteredList}
        />
        <Box mt={3}>
          <Results reload={setFilteredList} products={filteredList} />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductListView;
