import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomPopUp from '../../../components/CustomPopUp';
import MuiAlert from '@material-ui/lab/Alert';
import api from '../../../utils/api/Api';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Button,
  Snackbar,
  Backdrop,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import FormProduct from 'src/components/FormProduct';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  btnDanger: {
    padding: '.4rem',
    margin: '.2rem',
    color: theme.palette.danger.color,
    backgroundColor: theme.palette.danger.main,
    transition: 'all ease-in-out 300ms',
    '&:hover': {
      backgroundColor: theme.palette.danger.main,
      filter: 'brightness(0.5)'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  },
  button: {
    padding: '.4rem',
    margin: '.2rem',
    backgroundColor: theme.palette.primary.main,
    transition: 'all ease-in-out 300ms',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      filter: 'brightness(0.5)',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Results = ({ className, products, reload, ...rest }) => {
  const classes = useStyles();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleSelectAll = event => {
    let newSelectedProductIds;

    if (event.target.checked) {
      newSelectedProductIds = products.map(product => product.id);
    } else {
      newSelectedProductIds = [];
    }

    setSelectedProductIds(newSelectedProductIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };

  const remove = async () => {
    let response = await api.removeProduct(selectedProduct.id);
    if (response) {
      let results = products.filter(result => result.id !== selectedProduct.id);
      reload(results);
      setShowDeleteDialog(false);
    } else {
      setFailAlert(true);
      setShowDeleteDialog(false);
    }
  };

  const editOrCreate = async newProduct => {
    setLoading(true);
    const isUpdate = products.find(prod => prod.id === newProduct.id);
    if (isUpdate) {
      const response = await api.editProduct(newProduct);
      if (response) {
        setSucessAlert(true);
        setOpen(false);
        const newList = [];
        products.forEach(product =>
          newList.push(product.id === newProduct.id ? newProduct : product)
        );
        reload(newList);
      } else {
        setFailAlert(true);
      }
    } else {
      setFailAlert(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={1500} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Produto salvo com Sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={1500} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao salvar Produto!
        </Alert>
      </Snackbar>
      <FormProduct
        show={open}
        setShow={setOpen}
        item={selectedProduct}
        saveItem={editOrCreate}
      />
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProductIds.length === products.length}
                      color="primary"
                      indeterminate={
                        selectedProductIds.length > 0 &&
                        selectedProductIds.length < products.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Marca</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Materiais</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Ultima Atualização</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products
                  .slice(page * limit, page * limit + limit)
                  .map(product => (
                    <TableRow
                      hover
                      key={product.id}
                      selected={selectedProductIds.indexOf(product.id) !== -1}
                    >
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell>{product.id}</TableCell>
                      <TableCell>
                        <strong>{product.brand}</strong>
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar
                            className={classes.avatar}
                            src={product.imageUrl}
                          ></Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {product.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <List dense={true}>
                          {product.material.map(b => (
                            <ListItem>
                              <ListItemText primary={b} />
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                      <TableCell>
                        {new Date(product.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(product.updatedAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <IconButton
                            size="small"
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                              setSelectedProduct(product);
                              setOpen(true);
                            }}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            variant="contained"
                            className={classes.btnDanger}
                            onClick={() => {
                              setSelectedProduct(product);
                              setShowDeleteDialog(!showDeleteDialog);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <CustomPopUp
                            visible={showDeleteDialog}
                            setShow={setShowDeleteDialog}
                            defaultActionTitle="Não"
                            message="Deseja mesmo remover esse item?"
                            key={product.id}
                          >
                            <Button
                              onClick={() => {
                                remove();
                              }}
                            >
                              Sim
                            </Button>
                          </CustomPopUp>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={products.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired
};

export default Results;
