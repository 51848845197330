import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
  Backdrop,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormProduct from 'src/components/FormProduct';
import Api from 'src/utils/api/Api';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toolbar = ({ className, filter, reload, products, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };

  const saveProduct = async product => {
    setLoading(true);
    const response = await Api.newProduct(product);
    if (response) {
      setSucessAlert(true);
      reload([...products, product]);
    } else {
      setFailAlert(true);
    }
    setLoading(false);
  };

  return (
    <div
      style={{ paddingLeft: '15px' }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Produto salvo com Sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao salvar Produto!
        </Alert>
      </Snackbar>
      <FormProduct
        show={open}
        setShow={setOpen}
        item={{}}
        saveItem={saveProduct}
      />
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={6}
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Buscar Produto"
              variant="outlined"
              onChange={filter}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            justify="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Button
              variant="contained"
              color="primary"
              position="end"
              justify="flex-end"
              size="large"
              onClick={() => setOpen(!open)}
            >
              Novo Produto
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
