import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../utils/api/Api';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& > *': {
      outline: 'none !important'
    }
  },
  submitButton: {
    borderRadius: 60,
    marginTop: theme.spacing(2),
    border: `3px solid ${theme.palette.background.default}`,
    boxShadow: theme.shadows[26],
    color: 'white',
    '&:hover': {
      boxShadow: theme.shadows[25]
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <Formik
            initialValues={{
              email: 'usuario@email.com',
              password: 'senha'
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Email inválido.')
                .max(255)
                .required('Email obrigatório'),
              password: Yup.string()
                .max(255)
                .required('Senha obrigatória')
            })}
            onSubmit={async values => {
              const logged = await api.login({ ...values });
              logged && navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="center" mb={3}>
                  <Typography className="bold" color="primary" variant="h2">
                    Acessar sua conta.
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  label="Email"
                  variant="outlined"
                  value={values.email}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                />
                <Box
                  my={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}
                  >
                    entrar agora
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Novo por aqui?{' '}
                  <Link component={RouterLink} to="/register" variant="h6">
                    Cadastre-se
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
