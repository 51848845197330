import React from 'react';
import { Link, Link as RouterLink, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  Button,
  Hidden,
  Box
} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent'
  },
  toolbar: {
    height: 64
  },
  navLink: {
    padding: '.5rem'
  }
});

const TopBar = () => {
  const classes = useStyles();
  const match = useMatch({ path: '/login' });

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Typography color="primary" variant="h3">
            e-sobras
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <Link to={match ? '/register' : '/login'}>
            <Typography
              className={classes.navLink}
              color="primary"
              variant="h6"
            >
              {match ? 'cadastre-se' : 'entrar'}
            </Typography>
          </Link>
          <Button color="primary" variant="outlined">
            + Anunciar
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
