import React, { useState, useEffect } from 'react';
import api from 'src/utils/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    const getMateriais = async () => {
      let materiais = await api.getMaterialsBySegment(8);
      await setMaterials(materiais);
    };
    getMateriais();
  }, []);
  const addMaterial = newMaterial => {
    setMaterials([...materials, newMaterial]);
  };
  return (
    <Page className={classes.root} title="Materiais">
      <Container maxWidth={false}>
        <Toolbar reload={addMaterial} />
        <Box mt={3}>
          <Results reload={setMaterials} customers={materials} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
