import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  Select,
  Box,
  Input,
  Chip,
  Typography
} from '@material-ui/core';
import Api from 'src/utils/api/Api';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  imageBox: {
    width: '100%',
    height: '250px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundOrigin: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1.5)
  },
  multiSelect: {
    border: `solid thin`,
    borderColor: theme.palette.secondary.light,
    borderRadius: '.3rem',
    '& input': {
      border: 'none'
    }
  },
  upper: {
    '& *': {
      textTransform: 'uppercase'
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const FormProduct = ({ className, show, setShow, item, saveItem, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [segments, setSegments] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [fileSize, setFileSize] = useState('');

  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  const validateAndSetNewItem = handleItem => {
    if (handleItem && Object.keys(handleItem).length > 0) {
      setNewItem(item);
    }
  };

  useEffect(() => {
    setOpen(show);
    validateAndSetNewItem(item);
  }, [show, item]);

  const fetchSegments = async () => {
    const response = await Api.getSegments();
    if (response.length > 0) {
      setBrands(getBrands(response));
      setSegments(response);
    }
  };

  const getBrands = categories => {
    const brandsList = [];
    for (const category of categories) {
      category.brands.forEach(brand => {
        brandsList.push(brand.name);
      });
    }
    return brandsList;
  };

  const getMaterials = async () => {
    if (newItem && newItem.categoryId) {
      const response = await Api.getMaterialsBySegment(newItem.categoryId);
      if (response) {
        setMaterials(response);
      }
    }
  };

  useEffect(() => {
    fetchSegments();
  }, []);

  useEffect(() => {
    getMaterials();
  }, [newItem.categoryId]);

  useEffect(() => {
    if (materials.length <= 0) {
      setNewItem({ ...newItem, material: [] });
    }
  }, [materials]);

  const handleSubmit = () => {};

  const handleChange = e => {
    let { value, name } = e.target;
    if (value && (name === 'brand' || name === 'name')) {
      value = value.toUpperCase();
    }
    setNewItem({ ...newItem, [name]: value });
  };

  function handleSelectedImage(e) {
    const { files } = e.target;
    let nBytes = 0,
      nFiles = files.length;
    renderImage(files[0]);
    for (let nFileId = 0; nFileId < nFiles; nFileId++) {
      nBytes += files[nFileId].size;
    }
    let sOutput = nBytes + ' bytes';
    for (
      let aMultiples = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'],
        nMultiple = 0,
        nApprox = nBytes / 1024;
      nApprox > 1;
      nApprox /= 1024, nMultiple++
    ) {
      sOutput =
        nApprox.toFixed(3) +
        ' ' +
        aMultiples[nMultiple] +
        ' (' +
        nBytes +
        ' bytes)';
    }
    setFileSize(`tamanho do arquivo: ${sOutput}`);
  }

  function renderImage(file) {
    var reader = new FileReader();
    reader.onload = function(event) {
      setNewItem({ ...newItem, imageUrl: event.target.result });
    };
    reader.readAsDataURL(file);
  }

  async function handleFileUpload() {
    const { files } = document.getElementById('uploadInput');
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0], files[0].name);
      const response = await Api.uploadImage(formData);
      if (response && response.url) {
        return response.url;
      } else {
        return false;
      }
    } else if (newItem.imageUrl) {
      return newItem.imageUrl;
    } else {
      return false;
    }
  }

  const handleSegmentChange = async e => {
    if (item && item.categoryId !== e.target.value) {
      await setNewItem({ ...newItem, material: [] });
    }
    if (item && item.categoryId === e.target.value) {
      alert(
        `${item.categoryId} vs ${e.target.value} -> ${typeof item.material}`
      );
      await setNewItem({ ...newItem, material: item.material });
    }
    handleChange(e);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {item && Object.keys(item).length <= 0
          ? 'Novo Produto'
          : 'Editar Produto'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {item && Object.keys(item).length <= 0
            ? 'Para cadastrar um novo produto basta inserir as informações e clicar em salvar.'
            : 'Altere as informações desejadas e clique em salvar.'}
        </DialogContentText>
        <form style={{ marginTop: '1rem' }} onSubmit={handleSubmit}>
          <div
            style={{ backgroundImage: `url(${newItem.imageUrl})` }}
            className={classes.imageBox}
          ></div>
          <Box>
            <TextField
              id="uploadInput"
              accept=".png, .jpg, .jpeg"
              className={classes.noLabel}
              type="file"
              fullWidth
              placeholder="selecione uma imagem"
              title="Imagem do produto"
              onChange={handleSelectedImage}
            />
            <Typography
              className={classes.noLabel}
              align="right"
              color="primary"
              variant="h6"
            >
              {fileSize}
            </Typography>
          </Box>
          <Autocomplete
            fullWidth
            value={newItem.brand}
            inputValue={newItem.brand}
            onInputChange={(event, newInputValue) => {
              setNewItem({ ...newItem, brand: newInputValue.toUpperCase() });
            }}
            freeSolo
            options={brands}
            renderInput={params => (
              <TextField
                className={classes.upper}
                {...params}
                error={false}
                helperText={''}
                label="Marca"
                name="brand"
                margin="normal"
                variant="outlined"
              />
            )}
          />
          <TextField
            error={false}
            fullWidth
            helperText={''}
            label="Nome do Produto"
            margin="normal"
            name="name"
            onChange={handleChange}
            value={newItem.name}
            variant="outlined"
          />
          <TextField
            error={false}
            fullWidth
            helperText={''}
            label="Segmento"
            margin="normal"
            name="categoryId"
            onChange={handleSegmentChange}
            value={newItem.categoryId}
            variant="outlined"
            select
          >
            {segments.map(segment => (
              <MenuItem key={segment.id} value={segment.id}>
                {segment.name}
              </MenuItem>
            ))}
          </TextField>
          <Box>
            <Typography
              className={classes.noLabel}
              variant="caption"
              color="textSecondary"
              htmlFor="material-select"
              component="label"
            >
              Material
            </Typography>
            <Select
              id="material-select"
              variant="outlined"
              disabled={materials.length <= 0}
              multiple
              fullWidth
              name="material"
              labelId="material-label"
              className={classes.multiSelect}
              value={newItem.material}
              onChange={handleChange}
              input={<Input color="secondary" id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      color="primary"
                      key={value}
                      label={value}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {materials.map(material => (
                <MenuItem key={material.id} value={material.name}>
                  {material.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={async () => {
            const response = await handleFileUpload();
            if (!response) {
              alert('Erro ao fazer upload de imagem, tente novamente.');
              return;
            } else {
              const itemToSave = { ...newItem, imageUrl: response };
              saveItem(itemToSave);
            }
          }}
          color="primary"
        >
          {item && Object.keys(item).length <= 0 ? 'Salvar' : 'Editar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormProduct;
