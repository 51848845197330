import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import api from 'src/utils/api/Api';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  CircularProgress,
  Backdrop,
  Snackbar
} from '@material-ui/core';
import FormBrand from '../../../components/FormBrand';
import { Search as SearchIcon } from 'react-feather';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  desktopDrawer: {
    width: '100%',
    top: 0,
    height: 'calc(50% - 64px)'
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toolbar = ({ className, reload, filter, brands, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };
  const saveNewBrand = async name => {
    setLoading(true);
    let response = await api.createBrand(name);
    if (response.error) {
      setFailAlert(true);
    } else {
      setOpen(false);
      setSucessAlert(true);
      const newBrands = [...brands, { ...response.data }];
      console.dir(newBrands);
      reload(newBrands);
    }
    setLoading(false);
  };

  return (
    <div
      style={{ paddingLeft: '15px' }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Marca Cadastrada com Sucesso
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao Cadastrar Marca
        </Alert>
      </Snackbar>
      <FormBrand show={open} setShow={setOpen} saveBrand={saveNewBrand} />
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={6}
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Buscar Marca"
              variant="outlined"
              onChange={filter}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            justify="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              position="end"
              justify="flex-end"
              size="large"
            >
              Nova Marca
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
