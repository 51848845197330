import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import api from 'src/utils/api/Api';
import CustomPopUp from '../../../components/CustomPopUp';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
  Snackbar,
  IconButton
} from '@material-ui/core';
import FormBrand from '../../../components/FormBrand';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  btnDanger: {
    color: theme.palette.danger.color,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      filter: 'brightness(.8)',
      backgroundColor: theme.palette.danger.main
    }
  },
  editButton: {
    color: theme.palette.text.hint,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      filter: 'brightness(.8)',
      backgroundColor: theme.palette.primary.main
    }
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Results = ({ className, brands, reload, ...rest }) => {
  const classes = useStyles();
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [open, setOpen] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleSelectAll = event => {
    let newSelectedBrandIds;

    if (event.target.checked) {
      newSelectedBrandIds = brands.map(brand => brand.id);
    } else {
      newSelectedBrandIds = [];
    }

    setSelectedBrandIds(newSelectedBrandIds);
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const removeBrand = async brand => {
    let response = await api.deleteBrand(brand.id);
    if (response) {
      let newBrands = brands.filter(b => b.id !== brand.id);
      reload(newBrands);
      setShowDeleteDialog(false);
    }
  };

  const editBrand = async newName => {
    setLoading(true);
    let brand = selectedBrand;
    brand.name = newName;
    let response = await api.updateBrand(brand);
    if (response) {
      let newBrands = brands.map(b => {
        if (b.id === brand.id) b.name = newName;
        return b;
      });
      reload(newBrands);
      setOpen(false);
      setSucessAlert(true);
    } else {
      setFailAlert(true);
    }
    setLoading(false);
  };

  return (
    <>
      <CustomPopUp
        visible={showDeleteDialog}
        setShow={setShowDeleteDialog}
        defaultActionTitle="Não"
        message="Deseja mesmo remover esse item?"
        key={selectedBrand.id}
      >
        <Button onClick={() => removeBrand(selectedBrand)}>Sim</Button>
      </CustomPopUp>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Marca Atualizada com Sucesso
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao Atualizar Marca
        </Alert>
      </Snackbar>
      <FormBrand
        show={open}
        setShow={setOpen}
        item={selectedBrand.name}
        saveBrand={editBrand}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedBrandIds.length === brands.length}
                      color="primary"
                      indeterminate={
                        selectedBrandIds.length > 0 &&
                        selectedBrandIds.length < brands.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell
                    style={{ alignItems: 'center', textAlign: 'center' }}
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {brands.slice(page * limit, page * limit + limit).map(brand => {
                  brand['showDeleteDialog'] = false;
                  return (
                    <TableRow
                      hover
                      key={brand.id}
                      selected={selectedBrandIds.indexOf(brand.id) !== -1}
                    >
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell>{brand.id}</TableCell>
                      <TableCell>
                        <b> {brand.name}</b>
                      </TableCell>
                      <TableCell>
                        <Grid item xs={12}>
                          <Grid container justify="center" spacing={2}>
                            <Grid key={1} item>
                              <IconButton
                                variant="contained"
                                color="primary"
                                className={classes.editButton}
                                onClick={() => {
                                  setSelectedBrand(brand);
                                  setOpen(true);
                                }}
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            </Grid>
                            <Grid key={1} item>
                              <IconButton
                                variant="contained"
                                color="danger"
                                className={classes.btnDanger}
                                onClick={() => {
                                  setSelectedBrand(brand);
                                  setShowDeleteDialog(!showDeleteDialog);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={brands.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
