import axios from 'axios';
import PopUp from '../PopUp';
import { CONSTANTS } from '../../config';
import { StatusCodes, getReasonPhrase } from 'http-status-codes';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 15 * 60 * 10000
});

const popUp = new PopUp();

function getToken() {
  let token = localStorage[CONSTANTS.tokenKey];
  return token ? 'Bearer ' + token.replace(/"/g, '') : '';
}

const handleResponseErrors = {
  [StatusCodes.UNAUTHORIZED]: error => {
    popUp.open(getReasonPhrase(StatusCodes.UNAUTHORIZED), 'OK', () => {
      localStorage.clear();
      window.location = '/';
    });
  },
  [StatusCodes.FORBIDDEN]: error => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.incompleteRegister) {
      popUp.open(getReasonPhrase(StatusCodes.FORBIDDEN), 'OK', () => {
        window.location = '/conversor/profile';
      });
    }
  },
  [StatusCodes.BAD_REQUEST]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status
  }),
  [StatusCodes.NOT_FOUND]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status
  }),
  [StatusCodes.INTERNAL_SERVER_ERROR]: error => ({
    error: error.response.data.error || error.response.data,
    status: error.response.status,
    message: getReasonPhrase(StatusCodes.INTERNAL_SERVER_ERROR)
  })
};

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    console.error(error);
    alert(error.response.status);
    return handleResponseErrors[error.response.status](error);
  }
);

const http = axios.create({
  baseURL: CONSTANTS.host,
  headers: {
    Authorization: getToken(),
    'Content-Type': 'application/json'
  },
  timeout: 10000,
  adapter: cache.adapter
});

export default http;
