import PopUp from './PopUp';

export const popUp = new PopUp();
export { dateMask, phoneMask, cepMask } from './masks';

export function generateUniqueId(prefix) {
  if (prefix) {
    return Date.now() + prefix.toString() + Math.round(Math.random() * 1000);
  } else {
    return Date.now() + 'UUID' + Math.round(Math.random() * 1000);
  }
}

export function normalizeAndIgnoreDacritics(string) {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}
