import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import api from 'src/utils/api/Api';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Backdrop,
  Snackbar
} from '@material-ui/core';
import FormDialog from '../../../components/FormDialog'
import { Search as SearchIcon } from 'react-feather';


const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  desktopDrawer: {
    width: "100%",
    top: 0,
    height: 'calc(50% - 64px)'
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toolbar = ({ className,  reload, ...rest}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  }
  const saveNewCategory = async (newCategory) => {
    setLoading(true);
    let response = await api.newCategory(newCategory)
    if(response) {
      setOpen(false)
      setSucessAlert(true)
      reload(response)
    }else{
      setFailAlert(true)
    }
    setLoading(false)
  };
  
  return (
    <div style={{paddingLeft:"15px"}} className={clsx(classes.root, className)} {...rest}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity="success">
        Categoria Cadastrada com Sucesso
      </Alert>
    </Snackbar>
    <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity="error">
        Falha ao Cadastrar Categoria
      </Alert>
    </Snackbar>
       <FormDialog show={open} setShow={setOpen} saveNewCategory={saveNewCategory} />
      <Box mt={2}>
       
<Grid  container spacing={1}>
  <Grid container item xs={6} justify="flex-start" alignItems="center" spacing={3}>
    <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          placeholder="Buscar Categoria"
          variant="outlined"
        />
  </Grid>
  <Grid container item xs={6} justify="flex-end" alignItems="center" spacing={3}>
  <Button variant="contained" color="primary" onClick={handleClickOpen} position="end" justify="flex-end" size="large">Nova Categoria</Button>
  </Grid>
</Grid>
        
      </Box>
      
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
