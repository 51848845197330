import React, { useState, useEffect } from 'react';
import api from 'src/utils/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      let usuarios = await api.getUsers();
      await setUsers(usuarios);
      setFilteredList(usuarios);
    };
    getUsers();
  }, []);

  function normalizeAndIgnoreDacritics(string) {
    return string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  const search = event => {
    const { value } = event.target;
    console.log(value);
    if (!value) {
      setFilteredList(users);
      return;
    }
    const filteredResults = users.filter(usuario =>
      normalizeAndIgnoreDacritics(
        usuario.name.concat(' ' + usuario.surname + ' ' + usuario.email)
      ).includes(normalizeAndIgnoreDacritics(value))
    );
    setFilteredList(filteredResults);
  };

  return (
    <Page className={classes.root} title="Usuários">
      <Container maxWidth={false}>
        <Toolbar
          reload={setFilteredList}
          filter={search}
          users={filteredList}
        />
        <Box mt={3}>
          <Results reload={setUsers} customers={filteredList} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
