import React, { useState, useEffect } from 'react';
import api from 'src/utils/api/Api';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getCategories = async () => {
      let categorias = await api.getSegments();
      await setCategories(categorias);
    };
    getCategories();
  }, []);

  const addCategory = (newCategory) => {
    setCategories([...categories, newCategory])
  };

  return (

    <Page className={classes.root} title="Categorias">
      <Container maxWidth={false}>
        <Toolbar reload={addCategory} />
        <Box mt={3}>
          <Results reload={setCategories} customers={categories} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
