import http from './httpClient';
import { CONSTANTS } from '../../config';

const API_PATHS = {
  CATEGORY: '/category',
  MATERIAL: '/material',
  PRODUCT: '/product',
  PRODUCT_CATEGORY: '/product/category/',
  PRODUCT_LIST_BY_MATERIAL: '/product/material',
  USER_LIST: '/admin/list/users',
  USER_ACTIVATE: '/admin/user/activate/',
  USER_INACTIVATE: '/admin/user/inactivate/',
  BRAND: '/brand',
  LIST_BRAND: '/list/brand',
  SEARCH_ADS: '/ads/list',
  AD_BY_USER: '/ads/by-user',
  LOGIN: '/login'
};

function handleArrayResponse(response) {
  const { data } = response;
  if (Array.isArray(data) && data.length > 0) {
    return data;
  } else {
    return [];
  }
}

function handleLoginResponse(response) {
  localStorage.setItem(CONSTANTS.userKey, JSON.stringify(response.user));
  localStorage.setItem(CONSTANTS.tokenKey, response.token);
  return true;
}

export async function getSegments() {
  let response = await http.get(API_PATHS.CATEGORY);
  return response.error ? [] : handleArrayResponse(response);
}

export async function getUsers() {
  let response = await http.get(API_PATHS.USER_LIST);
  return response.error ? [] : handleArrayResponse(response);
}

export async function activeUser(id) {
  let response = await http.post(API_PATHS.USER_ACTIVATE + id);
  return response.error ? [] : handleArrayResponse(response);
}

export async function inactivateUser(id) {
  let response = await http.post(API_PATHS.USER_INACTIVATE + id);
  return response.error ? [] : handleArrayResponse(response);
}

export async function getMaterialsBySegment(categoryId) {
  let response = await http.get(API_PATHS.MATERIAL, {
    params: { categoryId }
  });
  return response.error ? [] : handleArrayResponse(response);
}

export async function getProductsByMaterialId(materialId) {
  let response = await http.get(
    API_PATHS.PRODUCT_LIST_BY_MATERIAL + materialId
  );
  return response.error ? [] : handleArrayResponse(response);
}

export async function getProductsByCategoryId(categoryId) {
  let response = await http.get(API_PATHS.PRODUCT_CATEGORY + categoryId);
  return response.error ? [] : handleArrayResponse(response);
}

export async function searchAds(query) {
  const response = await http.get(API_PATHS.SEARCH_ADS, {
    params: { ...query }
  });
  return response.error ? [] : handleArrayResponse(response);
}

export async function searchAdsByUser() {
  const response = await http.get(API_PATHS.AD_BY_USER);
  return response.error ? [] : handleArrayResponse(response);
}

export async function login(form) {
  const response = await http.post(API_PATHS.LOGIN, { ...form });
  return response.error ? false : handleLoginResponse(response.data);
}

export async function newCategory(category) {
  try {
    const response = await http.post(API_PATHS.CATEGORY, { name: category });
    return response.error ? false : response.data;
  } catch (error) {
    return false;
  }
}

export async function newMaterial(material) {
  try {
    const response = await http.post(API_PATHS.MATERIAL, { ...material });
    return response.error ? false : response.data;
  } catch (error) {
    return false;
  }
}

export async function removeCategory(category) {
  try {
    const response = await http.delete(API_PATHS.CATEGORY + '/' + category);
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function removeMaterial(material) {
  try {
    const response = await http.delete(API_PATHS.MATERIAL + '/' + material);
    console.log('reponse1: ', response);
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function editMaterial(material) {
  try {
    const response = await http.put(API_PATHS.MATERIAL + '/' + material.id, {
      ...material
    });
    console.log('reponse1: ', response);
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function editCategory(category) {
  try {
    const response = await http.put(API_PATHS.CATEGORY + '/' + category.id, {
      ...category
    });
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function removeProduct(id) {
  try {
    const response = await http.delete(API_PATHS.PRODUCT + '/' + id);
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function editProduct(product) {
  try {
    const response = await http.put(API_PATHS.PRODUCT + '/' + product.id, {
      ...product
    });
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function newProduct(product) {
  try {
    const response = await http.post(API_PATHS.PRODUCT, {
      ...product
    });
    return response.error ? false : true;
  } catch (error) {
    return false;
  }
}

export async function uploadImage(img) {
  try {
    const response = await http.post('/imgs', img);
    return response.error ? {} : response.data;
  } catch (error) {
    return {};
  }
}

export async function getBrands() {
  let response = await http.get(API_PATHS.LIST_BRAND);
  return response.error ? [] : handleArrayResponse(response);
}

export function createBrand(name) {
  return http.post(API_PATHS.BRAND, {
    name,
    categoryId: 8
  });
}

export function updateBrand(brandToUpdate) {
  return http.put(API_PATHS.BRAND + '/' + brandToUpdate.id, {
    ...brandToUpdate
  });
}

export function deleteBrand(id) {
  return http.delete(API_PATHS.BRAND + '/' + id);
}

export default {
  getSegments,
  getMaterialsBySegment,
  getProductsByMaterialId,
  getProductsByCategoryId,
  searchAds,
  searchAdsByUser,
  newCategory,
  newMaterial,
  login,
  removeCategory,
  removeMaterial,
  removeProduct,
  editCategory,
  editMaterial,
  editProduct,
  uploadImage,
  newProduct,
  getBrands,
  updateBrand,
  deleteBrand,
  createBrand,
  getUsers,
  activeUser,
  inactivateUser
};
