import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactJson from 'react-json-view';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Collapse,
  Typography
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormMaterial from 'src/components/FormMaterial';
import api from 'src/utils/api/Api';
import CustomPopUp from '../../../components/CustomPopUp';
import MuiAlert from '@material-ui/lab/Alert';

import Api from 'src/utils/api/Api';

const useStyles = makeStyles(theme => ({
  root: {},
  btnDanger: {
    color: theme.palette.danger.color,
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: '#9a0036'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));

const Results = ({ className, customers, reload, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const removeCustomer = async () => {
    let response = await api.removeMaterial(selectedMaterial.id);
    if (response) {
      let newCustomers = customers.filter(c => c.id !== selectedMaterial.id);
      reload(newCustomers);
      setShowDeleteDialog(false);
    }
  };

  const closeAlert = () => {
    setSucessAlert(false);
    setFailAlert(false);
  };

  const fetchCategories = async () => {
    const response = await Api.getSegments();
    if (response) {
      setCategories(response);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };
  const editMaterial = async newMaterial => {
    setLoading(true);

    let response = await api.editMaterial(newMaterial);
    if (response) {
      let newCustomers = customers.map(c => {
        if (c.id === newMaterial.id) c = newMaterial;
        return c;
      });

      reload(newCustomers);
      setOpen(false);
      setSucessAlert(true);
    } else {
      setFailAlert(true);
    }
    setLoading(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function getCategoryNameById(row) {
    let cat = categories.find(category => category.id === row.categoryId);
    if (cat) return cat.name;
    return '';
  }
  function Row(props) {
    const { row } = props;
    const [openRow, setOpenRow] = useState(false);

    return (
      <React.Fragment>
        <TableRow hover key={row.id} style={{ paddingLeft: '200px' }}>
          <TableCell align="center">{row.id}</TableCell>
          <TableCell>{getCategoryNameById(row)}</TableCell>
          <TableCell>
            <b> {row.name}</b>
          </TableCell>
          <TableCell>{row.unit}</TableCell>
          <TableCell>{row.unitLabel}</TableCell>
          <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
          <TableCell>{new Date(row.updatedAt).toLocaleDateString()}</TableCell>
          <TableCell>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid key={1} item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<EditOutlinedIcon />}
                    onClick={() => {
                      setSelectedMaterial(row);
                      setOpen(true);
                    }}
                  >
                    Editar
                  </Button>
                </Grid>
                <Grid key={1} item>
                  <Button
                    variant="contained"
                    color="danger"
                    className={classes.btnDanger}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setShowDeleteDialog(true);
                      setSelectedMaterial(row);
                    }}
                  >
                    Deletar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <CustomPopUp
        visible={showDeleteDialog}
        setShow={setShowDeleteDialog}
        defaultActionTitle="Não"
        message="Deseja mesmo remover esse item?"
        key={selectedMaterial.id}
      >
        <Button onClick={() => removeCustomer()}>Sim</Button>
      </CustomPopUp>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={sucessAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Material Atualizado com Sucesso
        </Alert>
      </Snackbar>
      <Snackbar open={failAlert} autoHideDuration={3000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error">
          Falha ao Atualizar Material
        </Alert>
      </Snackbar>

      <FormMaterial
        show={open}
        setShow={setOpen}
        item={selectedMaterial}
        saveNewMaterial={editMaterial}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow style={{ paddingLeft: 20 }}>
                  <TableCell align="center">ID</TableCell>
                  <TableCell>Segmento</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Unidade</TableCell>
                  <TableCell>Label da Unidade</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Ultima Atualização</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers
                  .slice(page * limit, page * limit + limit)
                  .map(customer => (
                    <Row key={customer.id} row={customer} />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
