export const CONSTANTS = {
  host: '//api.esobras.com.br',
  // host: '//esobras-api-com-br.umbler.net/api',
  basePath: '',
  usersOperation: '/user',
  loginOperation: '/login',
  forgotPassword: '/user/forgotPassword',
  resetPassword: '/user/resetPassword',
  activateUser: '/user/activate',
  userKey: '__es_us_',
  tokenKey: '__es_tk',
  fetchMaterials: '/material',
  fetchProducts: '/product/list-by-material',
  advertisement: '/advertisement',
  category: '/category',
  adsList: '/ads/list',
  locations: '/ads/locations'
};
