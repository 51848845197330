
import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles,
    Grid,
    Dialog,
    DialogActions,
    CircularProgress,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Container,
    Backdrop,
    Snackbar
  } from '@material-ui/core';

const FormDialog = ({ className,  show, setShow, item, saveNewCategory, ...rest}) => {
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("")
  const handleClose = () => {
    setOpen(false);
    setShow(false)
  };
  const handleClickOpen = () => {
    setOpen(true);
    setShow(true)
  };

  useEffect(() => {
    setOpen(show)
  },[show])
 
return (<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{!item ? "Nova Categoria" : "Editar Categoria"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {!item ? "Para cadastrar uma nova categoria, insira o nome da mesma abaixo e clique em salvar." : "Altere a descrição da Categoria e clique em Editar"}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Categoria"
              defaultValue={item}
              onChange={(e) => setNewCategory(e.target.value)}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => {saveNewCategory(newCategory)}} color="primary">
            {!item ? "Salvar" : "Editar"}
            </Button>
          </DialogActions>
        </Dialog>)

}
export default FormDialog;