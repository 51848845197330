import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Backdrop,
  Snackbar,
  InputLabel,
  FormControl,
  Typography,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Select
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import api from 'src/utils/api/Api';
import ReactJson from 'react-json-view';
import { Form } from 'formik';
import { generateUniqueId } from 'src/utils';
const FormDialog = ({
  className,
  show,
  setShow,
  item,
  saveNewMaterial,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [newMaterial, setNewMaterial] = useState({});
  const [categories, setCategories] = useState([]);
  const [formStructureHead, setFormStructureHead] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setShow(true);
  };
  const getCategories = async () => {
    let cats = await api.getSegments();
    setCategories(cats);
  };
  const handleMaterial = (field, value) => {
    setNewMaterial({ ...newMaterial, [field]: value });
  };

  const initiateMaterial = () => {
    let material = item ? item : { formStructure: {} };
    setNewMaterial(material);
  };

  const getFormStructureKeys = () => {
    if (
      newMaterial.formStructure &&
      Object.keys(newMaterial.formStructure).length > 0
    ) {
      let sortedkeys = [];
      for (let field in newMaterial.formStructure) {
        let ord = newMaterial.formStructure[field].order;
        sortedkeys[ord] = field;
      }
      return sortedkeys;
    }
    return [];
  };

  const getNameByOrder = order => {
    for (let field in newMaterial.formStructure) {
      if (newMaterial.formStructure[field].order == order) {
        return field;
      }
    }
    return '';
  };
  const changeFormStructureOrder = (actualOrder, newOrder) => {
    let material = { ...newMaterial };
    let newOrderField = getNameByOrder(actualOrder);
    let getChangedField = getNameByOrder(newOrder);
    material.formStructure[newOrderField].order = newOrder;
    material.formStructure[getChangedField].order = actualOrder;
    setNewMaterial({ ...material });
  };
  const setNewKey = (oldKey, event) => {
    const { name, value } = event.target;
    if (value) {
      let material = { ...newMaterial };
      material.formStructure[value] = { ...material.formStructure[oldKey] };
      delete material.formStructure[oldKey];

      setNewMaterial({ ...material });
    }
  };

  const removeFormStructureKey = key => {
    let material = { ...newMaterial };
    delete material.formStructure[key];
    setNewMaterial({ ...material });
  };
  const addFormStructureRow = () => {
    let material = { ...newMaterial };
    if (!material.formStructure) material.formStructure = {};
    material.formStructure['NOVA_CHAVE' + generateUniqueId()] = {
      order: Object.keys(newMaterial.formStructure).length
    };
    setNewMaterial({ ...material });
  };
  const handleFormStructure = (eventKey, event) => {
    console.log('CHAMOU O HANDLE');
    let { name, value } = event.target;

    let material = { ...newMaterial };
    if (name === 'values') value = value.split(',');
    if (name === 'type') {
      if (value === 'select' || value === 'check') {
        material.formStructure[eventKey].max = '';
        material.formStructure[eventKey].min = '';
      }
      if (value === 'number' || value === 'check') {
        material.formStructure[eventKey].values = [];
      }
    }
    material.formStructure[eventKey][name] = value;
    setNewMaterial({ ...material });
  };
  useEffect(() => {
    setOpen(show);
    getCategories();
    initiateMaterial();
  }, [show]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {!item ? 'Novo Material' : 'Editar Material'}
      </DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Material"
            defaultValue={newMaterial.name}
            onChange={e => handleMaterial('name', e.target.value)}
            type="text"
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            select
            id="category"
            label="Categoria"
            defaultValue={newMaterial.categoryId}
            onChange={e => handleMaterial('categoryId', e.target.value)}
            type="text"
            fullWidth
          >
            {categories.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            id="unit"
            label="Unidade"
            defaultValue={newMaterial.unit}
            onChange={e => handleMaterial('unit', e.target.value)}
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="unitLabel"
            label="Descrição da Unidade"
            defaultValue={newMaterial.unitLabel}
            onChange={e => handleMaterial('unitLabel', e.target.value)}
            type="text"
            fullWidth
          />
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              Estrutura do Formulario{' '}
              <IconButton onClick={addFormStructureRow}>
                <AddCircleIcon color="primary" />
              </IconButton>
            </Typography>
            <Table aria-label="purchases" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Ordem</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Unidade</TableCell>
                  <TableCell>Valores</TableCell>
                  <TableCell>Valor Max.</TableCell>
                  <TableCell>Valor Min.</TableCell>

                  <TableCell>Alt</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFormStructureKeys().map((key, index) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Box display="flex" flexDirection="row">
                        <IconButton
                          disabled={index === 0}
                          onClick={() =>
                            changeFormStructureOrder(index, index - 1)
                          }
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                          disabled={index === getFormStructureKeys().length - 1}
                          onClick={() =>
                            changeFormStructureOrder(index, index + 1)
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{ padding: '1px 0px 6px 16px' }}
                      component="th"
                      scope="row"
                    >
                      <TextField
                        margin="dense"
                        select
                        fullWidth
                        size="small"
                        name="type"
                        key={'type' + key}
                        id="form.type"
                        defaultValue={newMaterial.formStructure[key].type || ''}
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'select'}>Select</MenuItem>
                        <MenuItem value={'number'}>Numérico</MenuItem>
                        <MenuItem value={'check'}>Check</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell
                      style={{ padding: '1px 0px 6px 16px' }}
                      component="th"
                      scope="row"
                    >
                      <TextField
                        disabled={!key.startsWith('NOVA_CHAVE')}
                        size="small"
                        style={{ marginLeft: 0 }}
                        id="form.key"
                        name="key"
                        style={{ maxWidth: '120px' }}
                        onBlur={e => {
                          setNewKey(key, e);
                        }}
                        defaultValue={
                          key && !key.startsWith('NOVA_CHAVE') ? key : ''
                        }
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        size="small"
                        style={{ maxWidth: '140px' }}
                        id="form.label"
                        name="label"
                        key={'label' + key}
                        defaultValue={
                          newMaterial.formStructure[key].label || ''
                        }
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        style={{ maxWidth: '80px' }}
                        size="small"
                        id="form.unit"
                        key={'unit' + key + newMaterial.formStructure[key].type}
                        name="unit"
                        disabled={
                          newMaterial.formStructure[key].type === 'check'
                        }
                        defaultValue={newMaterial.formStructure[key].unit || ''}
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        size="small"
                        id="form.values"
                        name="values"
                        key={
                          'values' + key + newMaterial.formStructure[key].type
                        }
                        disabled={
                          newMaterial.formStructure[key].type !== 'select'
                        }
                        defaultValue={
                          newMaterial.formStructure[key].values || ''
                        }
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        size="small"
                        id="form.max"
                        key={'max' + key + newMaterial.formStructure[key].type}
                        name="max"
                        style={{ maxWidth: '70px' }}
                        disabled={
                          newMaterial.formStructure[key].type !== 'number'
                        }
                        defaultValue={newMaterial.formStructure[key].max || ''}
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        size="small"
                        id="form.min"
                        name="min"
                        key={'min' + key + newMaterial.formStructure[key].type}
                        style={{ maxWidth: '70px' }}
                        defaultValue={newMaterial.formStructure[key].min || ''}
                        disabled={
                          newMaterial.formStructure[key].type !== 'number'
                        }
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <TextField
                        margin="dense"
                        size="small"
                        id="form.alt"
                        name="alt"
                        key={'alt' + key}
                        defaultValue={newMaterial.formStructure[key].alt || ''}
                        onChange={e => handleFormStructure(key, e)}
                        type="text"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1px 0px 6px 16px' }}>
                      <IconButton onClick={() => removeFormStructureKey(key)}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            document.location.reload(true);
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            saveNewMaterial(newMaterial);
          }}
          color="primary"
        >
          {!item ? 'Cadastrar' : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormDialog;
